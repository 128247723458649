import React, { useState } from 'react';
import { screenshotImg } from '../data/data';
import TinySlider from "tiny-slider-react";
import "tiny-slider/dist/tiny-slider.css";
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';
import { Link } from 'react-router-dom';

export default function Screenshot() {
    const [open, setOpen] = useState<boolean>(false);
    const [activeIndex, setActiveIndex] = useState<number>(0);

    const settings = {
        container: '.tiny-four-item',
        controls: true,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        controlsText: ['<i class="mdi mdi-chevron-left "></i>', '<i class="mdi mdi-chevron-right"></i>'],
        nav: false,
        speed: 400,
        gutter: 10, // Add gutter between images
        responsive: {
            992: {
                items: 4
            },
            767: {
                items: 3
            },
            425: {
                items: 1
            },
        },
    };

    const openImage = (index: number) => {
        setActiveIndex(index);
        setOpen(true);
    };

    return (
        <div className="grid grid-cols-1 mt-6 relative">
            <div className="tiny-four-item">
                <TinySlider settings={settings}>
                    {screenshotImg.map((item: string, index: number) => (
                        <div className="tiny-slide" key={index} style={{ padding: '5px' }}> {/* Adding padding for gap */}
                            <Link to="#" onClick={() => openImage(index)} className="lightbox">
                                <img 
                                    src={item} 
                                    alt="" 
                                    style={{
                                        width: '100%',  // Makes the image fill the container
                                        height: '400px', // Set a fixed height for uniformity
                                        objectFit: 'cover', // Ensures the image covers the space without distortion
                                        borderRadius: '8px' // Optional: Adds rounded corners to images
                                    }} 
                                />
                            </Link>
                        </div>
                    ))}
                </TinySlider>
            </div>
            {open && (
                <Lightbox
                    mainSrc={screenshotImg[activeIndex]}
                    nextSrc={screenshotImg[(activeIndex + 1) % screenshotImg.length]}
                    prevSrc={screenshotImg[(activeIndex + screenshotImg.length - 1) % screenshotImg.length]}
                    onCloseRequest={() => setOpen(false)}
                    onMovePrevRequest={() => setActiveIndex((activeIndex + screenshotImg.length - 1) % screenshotImg.length)}
                    onMoveNextRequest={() => setActiveIndex((activeIndex + 1) % screenshotImg.length)}
                />
            )}
        </div>
    );
}
