
import phone1 from '../assets/images/phone/texas1.jpg'
import phone2 from '../assets/images/phone/4.jpg'
import phone3 from '../assets/images/phone/15.jpg'
import phone4 from '../assets/images/phone/6.jpg'
import phone5 from '../assets/images/phone/texas2.jpg'
import phone6 from '../assets/images/phone/6.png'
import phone7 from '../assets/images/phone/7.png'
import phone8 from '../assets/images/phone/8.png'

import client1 from '../assets/images/client/01.jpg'
import client2 from '../assets/images/client/02.jpg'
import client3 from '../assets/images/client/03.jpg'
import client4 from '../assets/images/client/04.jpg'
import client5 from '../assets/images/client/05.jpg'
import client6 from '../assets/images/client/06.jpg'

import company1 from '../assets/images/client/amazon.svg'
import company2 from '../assets/images/client/google.svg'
import company3 from '../assets/images/client/lenovo.svg'
import company4 from '../assets/images/client/paypal.svg'
import company5 from '../assets/images/client/shopify.svg'
import company6 from '../assets/images/client/spotify.svg'

import { FiDatabase, FiEye, FiFeather, FiHeart, FiMaximize, FiMonitor, FiNavigation2, FiPieChart, FiSmartphone, FiUserCheck } from "react-icons/fi"

export const feature = [
    {
        icon:FiMaximize,
        title:'Our Mission',
        desc:'At Texas Towing Company, our mission is to provide reliable and affordable towing services, ensuring prompt assistance and exceptional care for our customers.',
        active:true
    },
    {
        icon:FiMaximize,
        title:'Our Team',
        desc:'Our team of highly trained professionals is dedicated to delivering top-notch service with a friendly smile, ready to assist you in any towing scenario.',
        active:false
    },
    {
        icon:FiMaximize,
        title:'Our Services',
        desc:'We offer a variety of services, including emergency towing and roadside assistance, available 24/7 to help you whenever you need us.',
        active:true
    },
    {
        icon:FiMaximize,
        title:'Our Commitment to Safety',
        desc:'Safety is our top priority, and we adhere to strict protocols while using state-of-the-art equipment to ensure your vehicle is handled with care.',
        active:false
    },
]

export const feature2 = [
    {
        icon:FiMonitor,
        title:'Use On Any Device',
        desc:'One disadvantage of Lorum Ipsum is that in Latin frequently than others'
    },
    {
        icon:FiFeather,
        title:'Feather Icons',
        desc:'One disadvantage of Lorum Ipsum is that in Latin frequently than others'
    },
    {
        icon:FiEye,
        title:'Retina Ready',
        desc:'One disadvantage of Lorum Ipsum is that in Latin frequently than others'
    },
]
export const feature3 = [
    {
        icon:FiUserCheck,
        title:'W3c Valid Code',
        desc:'One disadvantage of Lorum Ipsum is that in Latin frequently than others'
    },
    {
        icon:FiSmartphone,
        title:'Fully Responsive',
        desc:'One disadvantage of Lorum Ipsum is that in Latin frequently than others'
    },
    {
        icon:FiHeart,
        title:'Browser Compatibility',
        desc:'One disadvantage of Lorum Ipsum is that in Latin frequently than others'
    },
]

export const screenshotImg = [
    phone1, phone2, phone3, phone4, phone5,
]

export const faq = [
    {
        id:1,
        title:'What services do you offer?',
        desc:'We provide a range of services, including emergency towing, roadside assistance, vehicle recovery, and long-distance towing.'
    },
    {
        id:2,
        title:'How quickly can you arrive?',
        desc:'Our average response time is 30 minutes or less, depending on your location and traffic conditions.'
    },
    {
        id:3,
        title:'Are your services available 24/7?',
        desc:'Yes, we are available 24/7, so you can count on us anytime you need assistance.'
    },
    {
        id:4,
        title:'Do you accept insurance?',
        desc:'Yes, we accept various insurance providers. Please check with us for specific coverage details.'
    },
]

export const clientData = [
    {
        image:client1,
        name:'Calvin Carlo',
        position:'Client',
        desc:`" The Texas Towing was a lifesaver when I found myself stranded with a dead battery. Their technician arrived quickly and got me back on the road in no time. Professional and friendly service highly recommended. "`,
        rate:['mdi mdi-star','mdi mdi-star','mdi mdi-star','mdi mdi-star','mdi mdi-star']
    },
    {
        image:client2,
        name:'Christa Smith',
        position:'Client',
        desc:`" I had a great experience with The Texas Towing. Their team was prompt and efficient when they helped me after I accidentally locked myself out of my car. They made a stressful situation so much easier. Thanks for the excellent service! "`,
        rate:['mdi mdi-star','mdi mdi-star','mdi mdi-star','mdi mdi-star','mdi mdi-star']
    },
    {
        image:client3,
        name:'Jemina CLone',
        position:'Client',
        desc:`" The Texas Towing provided top-notch service when my truck broke down on the highway. The tow truck arrived faster than expected, and the driver was courteous and knowledgeable. Great job, and I'll definitely use them again . "`,
        rate:['mdi mdi-star','mdi mdi-star','mdi mdi-star','mdi mdi-star','mdi mdi-star']
    },
    {
        image:client4,
        name:'Smith Vodka',
        position:'Client',
        desc:`"I was really impressed with The Texas Towing. They handled a tricky situation with my vehicle’s key programming swiftly and with great expertise. The whole process was smooth and hassle-free. I appreciate their professionalism! "`,
        rate:['mdi mdi-star','mdi mdi-star','mdi mdi-star','mdi mdi-star','mdi mdi-star']
    },
    // {
    //     image:client5,
    //     name:'Cristino Murfi',
    //     position:'Manager',
    //     desc:`" There is now an abundance of readable dummy texts. These are usually used when a text is required. "`,
    //     rate:['mdi mdi-star','mdi mdi-star','mdi mdi-star','mdi mdi-star','mdi mdi-star']
    // },
    // {
    //     image:client6,
    //     name:'Cristino Murfi',
    //     position:'Manager',
    //     desc:`" According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero. "`,
    //     rate:['mdi mdi-star','mdi mdi-star','mdi mdi-star','mdi mdi-star','mdi mdi-star']
    // },
]

export const companyData = [
    company1,company2,company3,company4,company5,company6
]