import React from 'react'
import Navbar from '../components/navbar'
import { Link } from 'react-router-dom'

import bg from '../assets/images/phone/texas2.jpg'
import app from '../assets/images/app.png'
import play from '../assets/images/play.png'
import phone from '../assets/images/phone/texas2.jpg'

import FeaturesOne from '../components/features-one'
import AboutOne from '../components/about-one'
import AboutTwo from '../components/about-two'
import Screenshot from '../components/screenshot'
import Faq from '../components/faq'
import Download from '../components/download'
import Client from '../components/client'
import Contact from '../components/contact'
import Partner from '../components/partner'
import Footer from '../components/footer'

export default function IndexOne() {
  return (
    <>
        <Navbar navLight={false} playBtn={false} bgLight={false} navCenter={false}/>

        <section className="relative overflow-hidden md:py-36 py-24 bg-slate-50/50 dark:bg-slate-800/20 bg-no-repeat bg-center bg-cover" id="home" style={{backgroundImage:`url(${bg})`}}>
            <div className="container relative">
                <div className="grid md:grid-cols-2 grid-cols-1 items-center mt-6 gap-[30px] relative">
                    <div className="md:me-6">
                        <h6 className="text-white uppercase text-sm font-bold tracking-wider mb-3">Welcome To</h6>
                        <h4 className="font-bold lg:leading-normal leading-normal text-[42px] lg:text-[54px] mb-5 text-white">The Texas Towing Professional Towing Service</h4>
                        <p className="text-white text-lg max-w-xl">The Texas Towing is a prominent towing and roadside assistance company based in Austin, Texas, renowned for its commitment to providing reliable and efficient services to the local community. Specializing in a comprehensive range of towing solutions, The Texas Towing offers 24/7 emergency towing, ensuring prompt response for vehicles that have broken down, been involved in accidents, or require urgent transport.</p>
                    
                        {/* <div className="mt-6">
                            <Link to="#"><img src={app} className="h-12 inline-block m-1" alt=""/></Link>
                            <Link to="#"><img src={play} className="h-12 inline-block m-1" alt=""/></Link>
                        </div> */}
                    </div>

                    {/* <div className="relative">
                        <img src={phone} className="mx-auto w-800  relative z-2" alt=""/>
                        <div className="overflow-hidden absolute md:size-[500px] size-[400px] bg-gradient-to-tl to-red-500/20 via-red-500/70 from-red-500 bottom-1/2 translate-y-1/2 md:start-0 start-1/2 ltr:md:translate-x-0 ltr:-translate-x-1/2 rtl:md:translate-x-0 rtl:translate-x-1/2 z-1 shadow-md shadow-red-500/10 rounded-full"></div>

                        <div className="overflow-hidden after:content-[''] after:absolute after:size-16 after:bg-red-500/20 after:top-0 after:end-6 after:z-1 after:rounded-lg after:animate-[spin_10s_linear_infinite]"></div>
                    </div> */}
                </div>
            </div>
        </section>

      
        <section className="relative md:py-24 py-16" id="features" style={{backgroundColor:'#1e83ab'}}>
            <div className="container relative">
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h6 className="text-white uppercase text-sm font-bold tracking-wider mb-3">About</h6>
                    <h4 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-bold">Know About Us</h4>

                    <p className="text-black-400 max-w-xl mx-auto">At Texas Towing Company, we are dedicated to providing fast, reliable, and safe towing solutions.</p>
                </div>

                <FeaturesOne/>
            </div>

            <div className="container relative md:mt-24 mt-16">
                <AboutOne/>
            </div>

            {/* <div className="container relative md:mt-24 mt-16">
                <AboutTwo/>
            </div> */}
        </section>

        <section className="relative md:py-24 py-16 bg-slate-50/50 dark:bg-slate-800/20" id="screenshot">
            <div className="container relative">
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h6 className="text-red-500 uppercase text-sm font-bold tracking-wider mb-3">Projects</h6>
                    <h4 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-bold">Look at Our Recent Projects</h4>

                    <p className="text-black-400 max-w-xl mx-auto">Check out our latest projects, showcasing our commitment to excellence and the successful resolution of challenging towing situations!</p>
                </div>
                <Screenshot/>
            </div>
        </section>

        <section className="relative overflow-hidden md:py-24 py-16" id="faqs" style={{backgroundColor:'#1e83ab'}}>
            <Faq/>
        </section>

        {/* <section className="relative md:py-24 py-16 bg-slate-50/50 dark:bg-slate-800/20" id="download">
            <Download/>
        </section> */}

        <section className="relative md:py-24 py-16" id="reviews">
            <div className="container relative">
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h6 className="text-red-500 uppercase text-sm font-bold tracking-wider mb-3">Reviews</h6>
                    <h4 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-bold">10k+ Customers Trust Us</h4>

                    {/* <p className="text-slate-400 max-w-xl mx-auto">Unleash the power of our platform with a multitude of powerful features, empowering you to achieve your goals.</p> */}
                </div>
                <Client/>
            </div>
        </section>

        <section 
    className="relative md:py-24 py-16" 
    id="contact" 
    style={{
        backgroundColor: '#1e83ab',
        boxShadow: '0 4px 20px rgba(255, 255, 255, 0.3)', // White shadow effect
        borderRadius: '8px' // Slight border radius
    }}
>
    <Contact/>
</section>

        {/* <section className="py-6 border-t border-b border-gray-100 dark:border-gray-800">
            <div className="container relative">
                <Partner/>
            </div>
        </section> */}
        
        <Footer/>
        
    </>
  )
}
