import React from 'react'
import { Link } from 'react-router-dom'

import phone from '../assets/images/phone/15.jpg'
import { FiAperture } from 'react-icons/fi'
import { IconType } from 'react-icons'

export default function Contact() {
    interface Data {
        icon: IconType;
        title: string;
        desc: string;
        link?: string; // Added optional link property
    }

    let data: Data[] = [
        {
            icon: FiAperture,
            title: 'Call',
            desc: '(512) 434 4242',
            link: 'tel:5124344242' // Link for calling
        },
        {
            icon: FiAperture,
            title: 'Email:',
            desc: 'texastowing8@gmail.com',
            link: 'mailto:texastowing8@gmail.com' // Link for email
        },
        {
            icon: FiAperture,
            title: 'Address(es):',
            desc: 'Austin, TX'
        },
        {
            icon: FiAperture,
            title: 'Service:',
            desc: '24/7 Available'
        },
    ]

    return (
        <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-6" style={{backgroundColor:'#1e83ab'}}>
            <div className="lg:col-span-5 md:col-span-6">
                <div className="pt-6 px-6 rounded-2xl">
                    <img src={phone} alt="" />
                </div>
            </div>

            <div className="lg:col-span-7 md:col-span-6">
                <div className="lg:ms-10">
                    <h6 className="text-white uppercase text-sm font-bold tracking-wider mb-3">Contact</h6>
                    <h4 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-bold">Get in Touch  <br /> With Us </h4>
                    <p className="text-black-400 max-w-xl">We're here to help! Whether you have questions about our services or need immediate assistance, feel free to reach out. Contact us via phone or email, and our team will respond promptly!</p>

                    <div className="grid lg:grid-cols-2 grid-cols-1 gap-6 mt-6">
                        {data.map((item: Data, index: number) => {
                            let Icon = item.icon
                            return (
                                <div className="group flex relative overflow-hidden p-6 rounded-md shadow dark:shadow-gray-800 bg-slate-50/50 dark:bg-slate-800/20 hover:bg-gray-700 dark:hover:bg-red-500 duration-500" key={index}>
                                    <span className=" group-hover:text-white text-5xl font-semibold duration-500">
                                        <Icon className="size-8 mt-2" />
                                    </span>
                                    <div className="flex-1 ms-3">
                                        <h5 className="group-hover:text-white text-lg font-semibold duration-500">
                                            {item.link ? (
                                                <a href={item.link} className="hover:underline">
                                                    {item.title}
                                                </a>
                                            ) : (
                                                item.title
                                            )}
                                        </h5>
                                        <p className="text-black-400 group-hover:text-white/50 duration-500 mt-2">
                                            {item.link ? (
                                                <a href={item.link} className="hover:underline">
                                                    {item.desc}
                                                </a>
                                            ) : (
                                                item.desc
                                            )}
                                        </p>
                                    </div>
                                    <div className="absolute start-1 top-5 text-slate-900/[0.02] dark:text-white/[0.03] text-8xl group-hover:text-white/[0.1] duration-500">
                                        <Icon className="size-24" />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
