import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Link as Link1 } from 'react-scroll';
import logoDark from '../assets/images/logo-dark.png';
import logoLight from '../assets/images/logo-light.png';
import appstore from '../assets/images/appstore.png';
import playstore from '../assets/images/playstore.png';
import { FiUser } from 'react-icons/fi';

export default function Navbar({ navLight, playBtn, bgLight, navCenter }: { navLight: boolean, playBtn: boolean, bgLight: boolean, navCenter: boolean }) {
    const [menu, setMenu] = useState<boolean>(false);
    const [scroll, setScroll] = useState<boolean>(false);
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

    const serviceAreas = [
        'Austin, TX',
        'Round Rock, TX',
        'Pflugerville, TX',
        'Hutto, TX',
        'Cedar Park, TX',
        'Liberty Hill, TX',
        'Leander, TX',
        'Buda, TX',
        'San Marcos, TX',
        'New Braunfels, TX',
        'Kyle, TX'
    ];

    useEffect(() => {
        const handlerScroll = () => {
            setScroll(window.scrollY > 50);
        };

        window.addEventListener('scroll', handlerScroll);
        return () => {
            window.removeEventListener('scroll', handlerScroll);
        };
    }, []);

    return (
        <nav className={`navbar ${bgLight ? 'bg-white dark:bg-slate-900 shadow dark:shadow-gray-800' : ''} ${scroll ? 'is-sticky' : ''}`} id="navbar" style={{backgroundColor:'#1e83ab'}}>
            <div className="container relative flex flex-wrap items-center justify-between" >
                <div className="nav-icons flex items-center lg_992:order-2 md:ms-6" >
                    <button type="button" className="collapse-btn inline-flex items-center ms-2 text-slate-900 dark:text-white lg_992:hidden" onClick={() => setMenu(!menu)} >
                        <span className="sr-only" >Navigation Menu</span>
                        <i className="mdi mdi-menu text-[24px]"></i>
                    </button>
                </div>

                <div className={`navigation lg_992:order-1 lg_992:flex ${navCenter ? '' : 'ms-auto'} ${menu ? '' : 'hidden'}`} id="menu-collapse">
                    <ul className={`navbar-nav  ${navLight ? 'nav-light' : ''}`} id="navbar-navlist">
                        <li className="nav-item ms-0">
                            <Link1 className="nav-link" activeClass="active" spy={true} smooth={true} duration={500} to="home">Home</Link1>
                        </li>
                        <li className="nav-item ms-0">
                            <Link1 className="nav-link" activeClass="active" spy={true} smooth={true} duration={500} to="features">About</Link1>
                        </li>
                        <li className="nav-item ms-0 relative">
                            <button
                                onClick={() => setDropdownOpen(!dropdownOpen)}
                                className="nav-link flex items-center justify-between"
                            >
                                Service Areas
                                <i className={`mdi mdi-chevron-${dropdownOpen ? 'up' : 'down'}`}></i>
                            </button>
                            {dropdownOpen && (
                                <ul className="absolute left-0 bg-gray-400 text-white shadow-lg mt-2 rounded-md w-48 max-h-60 overflow-y-auto">
                                    {serviceAreas.map((area, index) => (
                                        <li key={index} className="px-2 py-1 hover:bg-gray-700">
                                            <Link1 className="nav-link" activeClass="active" spy={true} smooth={true} duration={500} to="service-area-section">
                                                {area}
                                            </Link1>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                        <li className="nav-item ms-0">
                            <Link1 className="nav-link" activeClass="active" spy={true} smooth={true} duration={500} to="screenshot">Projects</Link1>
                        </li>
                        <li className="nav-item ms-0">
                            <Link1 className="nav-link" activeClass="active" spy={true} smooth={true} duration={500} to="faqs">FAQs</Link1>
                        </li>
                        <li className="nav-item ms-0">
                            <Link1 className="nav-link" activeClass="active" spy={true} smooth={true} duration={500} to="reviews">Reviews</Link1>
                        </li>
                        <li className="nav-item ms-0">
                            <Link1 className="nav-link" activeClass="active" spy={true} smooth={true} duration={500} to="contact">Contact us</Link1>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}
